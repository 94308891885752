import { Button } from '@/components//ui/button'
import { type ReactNode } from 'react'
import { Icon } from '@iconify/react'
import { Dialog, DialogContent, DialogTrigger } from '@/components/ui/dialog'

import {
  useSessionStorageAnswers,
  useSessionStorageOpenDialog,
} from '@/lib/hooks'

const question = {
  q: '滞在期間は？',
  a: {
    '１週間': {
      q: '現在の滞在国は？',
      a: {
        '日本': {
          q: '端末はSIMフリーですか？',
          a: {
            'YES': {
              q: 'eSIM対応機種ですか？',
              a: {
                'YES': 'A',
                'NO': 'B',
              },
            },
            'NO': 'E',
            '分からない': 'E',
          },
        },
        'タイ': {
          q: '端末はSIMフリーですか？',
          a: {
            'YES': {
              q: 'eSIM対応機種ですか？',
              a: {
                'YES': 'A',
                'NO': 'C',
              },
            },
            'NO': 'E',
            '分からない': 'E',
          },
        },
        'その他の国': {
          q: '端末はSIMフリーですか？',
          a: {
            'YES': {
              q: 'eSIM対応機種ですか？',
              a: {
                'YES': 'A',
                'NO': 'C',
              },
            },
            'NO': 'E',
            '分からない': 'E',
          },
        },
      },
    },
    '１カ月': {
      q: '現在の滞在国は？',
      a: {
        '日本': {
          q: '端末はSIMフリーですか？',
          a: {
            'YES': 'B',
            'NO': 'E',
            '分からない': 'E',
          },
        },
        'タイ': {
          q: '端末はSIMフリーですか？',
          a: {
            'YES': 'C',
            'NO': 'E',
            '分からない': 'E',
          },
        },
        'その他の国': {
          q: '端末はSIMフリーですか？',
          a: {
            'YES': 'C',
            'NO': 'E',
            '分からない': 'E',
          },
        },
      },
    },
    '１年以内': {
      q: '現在の滞在国は？',
      a: {
        '日本': {
          q: '端末はSIMフリーですか？',
          a: {
            'YES': {
              q: '月にどのくらいのデータ容量を使いますか？',
              a: {
                '3 GB': 'X-J-3GB',
                '4～6 GB': 'X-J-6GB',
                '7～10 GB': 'X-J-10GB',
                '11～15 GB': 'X-J-15GB',
                '16～20 GB': 'X-J-20GB',
                '容量を気にせず使いたい': 'X-J-Unlimited',
              },
            },
            'NO': 'E',
            '分からない': 'E',
          },
        },
        'タイ': {
          q: '端末はSIMフリーですか？',
          a: {
            'YES': {
              q: '月にどのくらいのデータ容量を使いますか？',
              a: {
                '3 GB': 'X-T-3GB',
                '4～6 GB': 'X-T-6GB',
                '7～10 GB': 'X-T-10GB',
                '11～15 GB': 'X-T-15GB',
                '16～20 GB': 'X-T-20GB',
                '容量を気にせず使いたい': 'X-T-Unlimited',
              },
            },
            'NO': 'E',
            '分からない': 'E',
          },
        },
        'その他の国': {
          q: '端末はSIMフリーですか？',
          a: {
            'YES': {
              q: '月にどのくらいのデータ容量を使いますか？',
              a: {
                '3 GB': 'X-J-3GB',
                '4～6 GB': 'X-J-6GB',
                '7～10 GB': 'X-J-10GB',
                '11～15 GB': 'X-J-15GB',
                '16～20 GB': 'X-J-20GB',
                '容量を気にせず使いたい': 'X-J-Unlimited',
              },
            },
            'NO': 'E',
            '分からない': 'E',
          },
        },
      },
    },
    '１年以上': {
      q: '現在の滞在国は？',
      a: {
        '日本': {
          q: '端末はSIMフリーですか？',
          a: {
            'YES': {
              q: '月にどのくらいのデータ容量を使いますか？',
              a: {
                '3 GB': 'Y-J-3GB',
                '4～13 GB': 'Y-J-13GB',
                '14～30 GB': 'Y-J-30GB',
                '31～60 GB': 'Y-J-60GB',
                '容量を気にせず使いたい': 'Y-J-Unlimited',
              },
            },
            'NO': 'E',
            '分からない': 'E',
          },
        },
        'タイ': {
          q: '端末はSIMフリーですか？',
          a: {
            'YES': {
              q: '月にどのくらいのデータ容量を使いますか？',
              a: {
                '3 GB': 'Y-T-3GB',
                '4～13 GB': 'Y-T-13GB',
                '14～30 GB': 'Y-T-30GB',
                '31～60 GB': 'Y-T-60GB',
                '容量を気にせず使いたい': 'Y-T-Unlimited',
              },
            },
            'NO': 'E',
            '分からない': 'E',
          },
        },
        'その他の国': {
          q: '端末はSIMフリーですか？',
          a: {
            'YES': {
              q: '月にどのくらいのデータ容量を使いますか？',
              a: {
                '3 GB': 'Y-J-3GB',
                '4～13 GB': 'Y-J-13GB',
                '14～30 GB': 'Y-J-30GB',
                '31～60 GB': 'Y-J-60GB',
                '容量を気にせず使いたい': 'Y-J-Unlimited',
              },
            },
            'NO': 'E',
            '分からない': 'E',
          },
        },
      },
    },
  },
}

const Flow = () => {
  const [open, setOpen] = useSessionStorageOpenDialog()
  const [answers, setAnswers] = useSessionStorageAnswers()

  const prev = () => {
    setAnswers(answers.slice(0, -1))
  }

  const close = () => {
    setOpen(false)
  }

  const answer = (value: string) => {
    setAnswers(answers.concat(value))
  }

  const isFirstQ = answers.length === 0
  const recursive: any = (question: {q: string, a: any}, answers: string[]) => {
    const answer = answers.shift()
    if (!answer) return question
    return recursive(question.a[answer], answers)
  }
  const qa: {q: string, a: {}} | string = recursive(question, [...answers])
  
  return (
    <div className='w-full'>
      {(typeof qa === 'string')
      ? <Result pattern={qa} onBack={prev} />
      : (
        <div className={`relative rounded-xl border-4 text-base border-brand-purple-700 bg-brand-purple-50 text-brand-purple-700 flex flex-col items-center ${!open ? 'bg-[url("/images/thailand-bg.svg")] bg-no-repeat bg-bottom' : 'pt-9 pb-5'}`}>
          {open && (
            <div className='absolute top-0 left-0 pt-1 px-1 w-full flex flex-row justify-between items-center'>
              {isFirstQ ? <div /> : <Button variant={'ghost'} size={'icon'} className=' hover:text-brand-purple-700 hover:bg-transparent bg-transparent'><Icon icon='mdi:chevron-left' className='h-8 w-8' onClick={prev} /></Button>}
              <Button variant={'ghost'} size={'icon'} className=' hover:text-brand-purple-700 hover:bg-transparent bg-transparent'><Icon icon='mdi:close' className='h-6 w-6' onClick={close} /></Button>
            </div>
          )}
          {(typeof qa === 'object') && <Question title={qa.q} options={Object.keys(qa.a)} onClick={answer} />}
        </div>
      )}
    </div>
  )
}

const Question = ({ title, options, onClick }: { title: string; options: string[] | string[][]; onClick: CallableFunction }) => {
  return (
    <>
      <div className='p-4 space-y-6'>
        <div className='text-black font-semibold text-center md:text-lg'>{title}</div>
        <div className='flex flex-row flex-wrap items-center justify-center gap-4'>
          {options
            .map((option) => (Array.isArray(option) ? option : [option, option]))
            .map(([label, value]) => (
              <Button
                className='rounded-full px-4 py-2 min-w-40 bg-white border-2 border-brand-purple-700 text-brand-purple-700 hover:bg-brand-purple-700 hover:text-white md:text-base'
                {...(onClick ? { onClick: () => onClick(value) } : {})}
                key={value}
              >
                {label}
              </Button>
            ))}
        </div>
      </div>
    </>
  )
}

const Result = ({ pattern, onBack }: { pattern: string; onBack: CallableFunction }) => {
  const [, setOpen] = useSessionStorageOpenDialog()
  const hasOption = /^[XY]-/.test(pattern)
  const isThai: boolean | null = hasOption ? pattern.substring(2, 3) === 'T' : null
  const howMuchData: string | null = hasOption ? pattern.substring(4) : null
  pattern = pattern.substring(0, 1)
  const price: number | null = ((pattern: string, howMuchData: string | null) => {
    if (pattern === 'X') {
      if (howMuchData === '3GB') return 790
      if (howMuchData === '6GB') return 890
      if (howMuchData === '10GB') return 990
      if (howMuchData === '15GB') return 1190
      if (howMuchData === '20GB') return 1390
      if (howMuchData === 'Unlimited') return 1990
    } else if (pattern === 'Y') {
      if (howMuchData === '3GB') return 490
      if (howMuchData === '13GB') return 790
      if (howMuchData === '30GB') return 990
      if (howMuchData === '60GB') return 1290
      if (howMuchData === 'Unlimited') return 1790
    }
    return null
  })(pattern, howMuchData)
  const INDEX_PATTERN = 0
  const INDEX_TITLE = 1
  const INDEX_SUBTITLE = 2
  const INDEX_DESCRIPTION = 3
  const INDEX_COMPONENT = 4
  const rows = [
    [
      'A',
      'TRAVeSIM',
      '旅行用プリペイドeSIM',
      'TRAVeSIM(トラベシム)は、世界140の国と地域で高速データ通信が利用出来ます！<br class="hidden md:block"/>SIMカードの抜き差し不要、QRコードを読み込むだけで、海外データ通信環境が普段使いのスマホに。',
      ComponentA,
    ],
    [
      'B',
      'ベリータイSIM',
      'タイ国内向けプリペイドSIM',
      'ベリータイSIMはタイに中長期滞在される方に最適なプリペイドSIMカードです。<br class="hidden md:block"/>日本人が常駐しているショップでのサポートがあるのでSIMカードの入れ替えが不安な方も安心。',
      ComponentB,
    ],
    [
      'C',
      'ベリータイSIM',
      'タイ国内向けプリペイドSIM',
      'ベリータイSIMはタイに中長期滞在される方に最適なプリペイドSIMカードです。<br class="hidden md:block"/>日本人が常駐しているショップでのサポートがあるのでSIMカードの入れ替えが不安な方も安心。',
      ComponentC,
    ],
    [
      'E',
      'SIMロック解除',
      '海外のSIMカードを使うには<br class="block md:hidden" />SIMフリースマホが必要です。',
      'SIMロックのかかったスマホではタイのSIMカードを利用出来ません。<br class="hidden md:block"/>スマホを購入したキャリアにてSIMロック解除の手続きを行いましょう！',
      ComponentE,
    ],
    [
      'X',
      'HAPPINESS',
      '契約期間縛りなし月額プラン',
      'HAPPINESS(ハピネス)シリーズの特徴は「契約期間の縛りなし」で契約出来る点です。<br class="hidden md:block"/>中長期タイに滞在される方でプリペイドタイプが面倒な人にお勧めです。',
      () => <ComponentX isThai={isThai!} howMuchData={howMuchData!} price={price!} />,
    ],
    [
      'Y',
      'ENJOY 5G',
      'AISの5G通信が使える大人気プラン',
      'ENJOY 5Gシリーズの特徴は「タイ最大手のAISの5G対応プラン」がお得にご契約出来る点です。<br class="hidden md:block"/>タイに赴任される方にまずお試し頂きたいプランです。',
      () => <ComponentY isThai={isThai!} howMuchData={howMuchData!} price={price!} />,
    ],
  ]
  const data = rows.find((cols) => cols[INDEX_PATTERN] === pattern)!
  const Component = data[INDEX_COMPONENT]

  return (
    <>
      <div
        className={`relative rounded-xl border-4 text-lg border-sim-${pattern}-main bg-sim-${pattern}-sub flex flex-col items-center pt-9 pb-5 px-5`}
      >
        <div className='absolute top-0 left-0 pt-1 px-1 w-full flex flex-row justify-between items-center'>
          <Button
            variant={'ghost'}
            size={'icon'}
            className={`hover:text-sim-${pattern}-main hover:bg-transparent bg-transparent text-sim-${pattern}-main`}
            {...(onBack ? { onClick: () => onBack() } : {})}
          >
            <Icon icon='mdi:chevron-left' className='h-9 w-9' />
          </Button>

          <Button
            variant={'ghost'}
            size={'icon'}
            className={`hover:text-sim-${pattern}-main hover:bg-transparent bg-transparent text-sim-${pattern}-main`}
            onClick={() => setOpen(false)}
          >
            <Icon icon='mdi:close' className='h-6 w-6' />
          </Button>
        </div>
        {pattern === 'E' 
          ? <p className='text-center font-bold text-sm md:text-2xl'>SIMカードの準備の前にスマホの準備を！</p>
          : <p className='text-center font-bold text-base md:text-2xl'>あなたにピッタリのタイのSIMカードは</p>
        }
        <div className={`py-4 w-full md:w-[700px] rounded-xl my-5 text-white bg-sim-${pattern}-main text-center`}>
          <small className='text-lg md:text-xl' dangerouslySetInnerHTML={{ __html: data[INDEX_SUBTITLE] }}></small>
          <h2
            className='text-4xl md:text-5xl font-bold'
            dangerouslySetInnerHTML={{ __html: data[INDEX_TITLE] + (howMuchData ? ` ${howMuchData}` : '') }}
          ></h2>
          {price && <small className='text-xl md:text-2xl'>月額{price.toLocaleString()}THB</small>}
        </div>
        <small className='text-sm md:text-center' dangerouslySetInnerHTML={{ __html: data[INDEX_DESCRIPTION] }}></small>
      </div>
      <Component />
    </>
  )
}

const ComponentLayout = ({ pattern, children, className }: { pattern: string; children: ReactNode; className?: string }) => {
  return (
    <>
      <div className={`relative rounded-xl border-4 text-lg border-sim-${pattern}-main flex flex-col items-center mt-5 p-5 px-5 bg-white ${className ?? ''}`}>
        {children}
      </div>
    </>
  )
}

// TRAVeSIM
const ComponentA = () => {
  return (
    <ComponentLayout pattern='A'>
      <h3 className='font-bold text-2xl md:text-4xl'>
        <span className='text-sim-A-main'>
          最短<span className='text-5xl md:text-7xl'>1</span>分
        </span>
        で利用開始！
      </h3>
      <p className='mt-5 font-bold text-xl md:text-4xl text-center'>
        お申し込みからご利用まで
        <br />
        かんたん
        <span className='text-sim-A-main'>
          <span className='text-3xl md:text-6xl'>3</span>ステップ！
        </span>
      </p>
      <p className='mt-5 text-xs md:text-base md:text-center'>
        SIMカードの購入～SIMカードのアクティベート～利用開始まで全てスマホ上の操作で完結！
        <br className='hidden md:block' /> WEBで簡単に申し込めて、申込直後にeSIM情報がメールに届きます。
      </p>
      <img className='mt-5' src='/images/pt-a-img1.jpg' />
      <a
        href='https://www.travesim.com/'
        target='_blank'
        className='mt-5 bg-sim-A-main text-white text-base md:text-3xl p-3 rounded-xl w-full md:w-[700px] text-center'
      >
        TRAVeSIM公式WEBサイトへ
      </a>
    </ComponentLayout>
  )
}

// タイSIM@Amazon
const ComponentB = () => {
  return (
    <ComponentLayout pattern='B'>
      <h3 className='font-bold text-xl md:text-3xl'>
        出国前に<span className='text-4xl md:text-6xl text-sim-B-main'>日本</span>でSIMを購入！
      </h3>
      <p className='mt-5 font-bold text-sm md:text-3xl text-center'>
        Amazonで販売しているベリータイSIMなら
        <br />
        タイの現地でも<span className='text-sim-B-main'>日本語サポート</span>で安心！
      </p>
      <p className='mt-5 text-xs md:text-base md:text-center'>
        Amazonの正規代理店で販売されているベリータイSIMはバンコクとシラチャに店舗を
        <br className='hidden md:block' />
        構えるベリーモバイルで日本語サポートが受けられるので万が一の時も安心！
      </p>
      <img className='mt-5' src='/images/pt-b-img1.jpg' />
      <a
        href='https://www.amazon.co.jp/【AIS正規代理店】ベリーモバイル-15日｜30GB-無料通話付き｜SNS使い放題-｜大容量タイプ-｜現地日本語サポート/dp/B0CDGBJ4GW/ref=sr_1_1?__mk_ja_JP=カタカナ&amp;crid=2GWRH8MOM57QJ&amp;keywords=ベリータイ+sim&amp;qid=1696926503&amp;sprefix=ベリータイsim%2Caps%2C323&amp;sr=8-1'
        target='_blank'
        className='mt-5 bg-sim-B-main text-white text-base md:text-3xl p-3 rounded-xl w-full md:w-[700px] text-center'
      >
        AmazonでベリータイSIMを購入
      </a>
    </ComponentLayout>
  )
}

// タイSIM@ベリーモバイル
const ComponentC = () => {
  return (
    <ComponentLayout pattern='C'>
      <h3 className='font-bold text-lg md:text-3xl'>
        タイに到着後<span className='text-2xl md:text-5xl text-sim-C-main'>日本語</span>でSIMを購入
      </h3>
      <p className='mt-5 font-bold text-sm md:text-3xl text-center'>
        ベリーモバイルには日本人が常駐しているので
        <br />
        日本語で<span className='text-sim-C-main'>SIMの設定</span>を行うので安心！
      </p>
      <p className='mt-5 text-xs md:text-base md:text-center'>
        ベリーモバイルでSIMカードをご購入される一番の理由は日本語サポートです。
        <br className='hidden md:block' />
        もしSIMロックが掛かっていても解除のお申し込みも可能です。
      </p>
      <img className='mt-5' src='/images/pt-c-img1.jpg' />
      <a
        href='https://www.berrymobile.jp/thailand/'
        target='_blank'
        className='mt-5 bg-sim-C-main text-white text-base md:text-3xl p-3 rounded-xl w-full md:w-[700px] text-center'
      >
        ベリーモバイル公式WEBサイト
      </a>
    </ComponentLayout>
  )
}

// SIMロック解除
const ComponentE = () => {
  return (
    <ComponentLayout pattern='E' className='space-y-6'>
      <h3 className='font-bold text-4xl md:text-6xl text-sim-E-main'>SIMロックの確認</h3>
      <p className='font-bold text-center text-sim-E-main text-base md:text-3xl'>まずはSIMロックを確認しましょう。</p>
      <p className='text-xs md:text-sm md:text-center'>
        大手キャリア（ドコモ、ソフトバンク、au）で端末を分割購入した場合は、SIMロックが掛かっている可能性があります。
        <br />
        SIMロックが掛かってる場合には、他キャリアをご利用頂けないため、キャリアにてSIMロック解除を
        <br className='hidden md:block' />
        行ってください。
      </p>
      <div className='grid grid-cols-1 md:grid-cols-3 gap-4'>
        <a href='https://www.docomo.ne.jp/support/unlock_simcard/' target='_blank'>
          <div className='border-2 border-slate-600 flex flex-col items-center px-4 w-48'>
            <div className='font-semibold text-[#cc3341]'>docomo</div>
            <div className='text-sm font-semibold pb-1'>SIMロック解除に関して</div>
          </div>
        </a>
        <a href='https://www.softbank.jp/mobile/support/usim/unlock_procedure/' target='_blank'>
          <div className='border-2 border-slate-600 flex flex-col items-center px-4 w-48'>
            <div className='font-semibold text-slate-500'>Softbank</div>
            <div className='text-sm font-semibold pb-1'>SIMロック解除に関して</div>
          </div>
        </a>
        <a href='https://www.au.com/support/service/mobile/procedure/simcard/unlock/' target='_blank'>
          <div className='border-2 border-slate-600 flex flex-col items-center px-4 w-48'>
            <div className='font-semibold text-[#eb5505]'>au</div>
            <div className='text-sm font-semibold pb-1'>SIMロック解除に関して</div>
          </div>
        </a>
      </div>
      <p className='text-xs'>※リンクをクリックすると各キャリアのWEBサイトにジャンプします。</p>
      <p className='font-bold text-center text-sim-E-main text-lg md:text-3xl'>
        新しい端末を購入するなら
        <br className='block md:hidden' />
        ベリーモバイル
      </p>
      <p className='text-xs md:text-base md:text-center'>
        タイで使えるSIMフリースマホを新たに購入するのであればベリーモバイルがおススメです。
        <br className='hidden md:block' />
        ベリーモバイルで端末を購入すると、お使いのスマホからのデータ移行や
        <br className='hidden md:block' />
        端末故障時の修理取次、代替機貸し出しなど充実したサポートを受けられます。
      </p>
      <a
        href='https://www.berrymobile.jp/thailand/services/berry/device'
        target='_blank'
        className='bg-sim-E-main text-white text-base md:text-3xl p-3 rounded-xl w-full md:w-[700px] text-center'
      >
        ベリーモバイルの取り扱い端末
      </a>
      <p className='mt-5 text-xs md:text-base md:text-center'>
        ベリーモバイルでSIMカードをご購入される一番の理由は日本語サポートです。
        <br className='hidden md:block' />
        もしSIMロックが掛かっていても解除のお申し込みも可能です。
      </p>        
      <img className='mt-5' src='/images/pt-c-img1.jpg' />
      <a
        href='https://www.berrymobile.jp/thailand/'
        target='_blank'
        className='mt-5 bg-sim-E-main text-white text-base md:text-3xl p-3 rounded-xl w-full md:w-[700px] text-center'
      >
        ベリーモバイル公式WEBサイト
      </a>
    </ComponentLayout>
  )
}

// HAPPINESS
const ComponentX = ({ isThai, howMuchData, price }: { isThai: boolean, howMuchData: string, price: number }) => {
  return (
    <ComponentLayout pattern='X'>
      <h3 className='font-bold text-lg md:text-3xl'>
        <span className='text-2xl md:text-5xl text-sim-X-main'>契約期間</span>の縛りなしでSIMを契約
      </h3>
      <p className='mt-5 font-bold text-sm md:text-3xl text-center'>
        短期留学などで滞在期間が
        <br className='block lg:hidden' />
        1年未満という方でも安心
        <br />
        <span className='text-sim-X-main'>契約期間の縛りが無い</span>ので誰でも契約可能！
      </p>
      <p className='mt-5 md:text-center text-xs md:text-base'>
        必要書類は「パスポート」/ 「日本の運転免許証※ご家族名義可」でOK！
        <br />
        まずはお気軽にお問合せください。
      </p>
      <p className='w-full md:w-[620px] border-t border-b border-black py-2 mt-5 text-center text-xs md:text-base font-bold'>
        {isThai ? <>オンラインで申込、タイ国内でSIMが受け取れる！</> : <>オンラインで申込、日本にいるうちに<br className="block md:hidden"/>タイのSIMが受け取れる！</>}
      </p>
      <div className='mt-5 flex items-center justify-center gap-2 md:gap-5'>
        <div className='size-20 md:size-40 bg-[#9DC3E6] rounded-full table'>
          <span className='table-cell align-middle text-center text-white text-xs md:text-base'>最低利用期間<br />1カ月</span>
        </div>
        <div className='size-20 md:size-40 bg-[#9DC3E6] rounded-full table'>
          <span className='table-cell align-middle text-center text-white text-xs md:text-base'>物理SIM<br className="block md:hidden" />{' '}or{' '}eSIM<br />が選べる！</span>
        </div>
        <div className='size-20 md:size-40 bg-[#9DC3E6] rounded-full table'>
          <span className='table-cell align-middle text-center text-white text-xs md:text-base'>リモートで<br />SIM契約が<br className="block md:hidden" />完結！</span>
        </div>
      </div>
      {!isThai && <p className="mt-5 text-[0.5rem] leading-3 md:text-xs">※日本国外在住者の場合は、日本の住所が必要となります。<br />ご住所登録欄に日本の住所を入力の上、eSIMでの受け取りをご指定ください。</p>}
      <a
        href={(isThai ? `${import.meta.env.PUBLIC_FORM_URL}/thailand/thaisimjp?thaisim_plan1_name=` : '/form?plan1_name=') + encodeURI(`HAPPINESS ${howMuchData}プラン 月額${price}THB-契約期間縛りなし`)}
        target='_blank'
        className='mt-5 border-2 bg-sim-X-main text-white p-2 rounded-xl block text-center text-base md:text-3xl w-full md:w-[700px]'
      >
        オンラインでタイのSIMカードを契約
      </a>
    </ComponentLayout>
  )
}

// ENJOY 5G
const ComponentY = ({ isThai, howMuchData, price }: { isThai: boolean, howMuchData: string, price: number }) => {
  return (
    <ComponentLayout pattern='Y'>
      <h3 className='font-bold text-lg md:text-3xl'>
        <span className='text-3xl md:text-6xl text-sim-Y-main'>5G対応</span>で
        <span className='text-3xl md:text-6xl text-sim-Y-main'>快適</span>なSIMを契約
      </h3>
      <p className='mt-5 font-bold text-sm md:text-3xl text-center'>
        <span className='text-sim-Y-main'>通信速度が安定</span>しているAISの5Gプランを
        <br />
        契約してタイで<span className='text-sim-Y-main'>快適なモバイルライフ</span>を！
      </p>
      <p className='mt-5 md:text-center text-xs md:text-base'>
        必要書類は「パスポート」/ 「日本の運転免許証※ご家族名義可」でOK！
        <br />
        まずはお気軽にお問合せください。
      </p>
      <p className='w-full md:w-[620px] border-t border-b border-black py-2 mt-5 text-center text-xs md:text-base font-bold'>
        {isThai ? <>オンラインで申込、タイ国内でSIMが受け取れる！</> : <>オンラインで申込、日本にいるうちに<br className="block md:hidden"/>タイのSIMが受け取れる！</>}
      </p>
      <div className='mt-5 flex items-center justify-center gap-2 md:gap-5'>
        <div className='size-20 md:size-40 bg-[#A9D18E] rounded-full table'>
          <span className='table-cell align-middle text-center text-white text-xs md:text-base'>高速通信<br className="block md:hidden"/>＆安心の<br />AIS回線</span>
        </div>
        <div className='size-20 md:size-40 bg-[#A9D18E] rounded-full table'>
          <span className='table-cell align-middle text-center text-white text-xs md:text-base'>物理SIM<br className="block md:hidden" />{' '}or{' '}eSIM<br />が選べる！</span>
        </div>
        <div className='size-20 md:size-40 bg-[#A9D18E] rounded-full table'>
          <span className='table-cell align-middle text-center text-white text-xs md:text-base'>リモートで<br />SIM契約が<br className="block md:hidden" />完結！</span>
        </div>
      </div>
      {!isThai && <p className="mt-5 text-[0.5rem] leading-3 md:text-xs">※日本国外在住者の場合は、日本の住所が必要となります。<br />ご住所登録欄に日本の住所を入力の上、eSIMでの受け取りをご指定ください。</p>}
      <a
        href={(isThai ? `${import.meta.env.PUBLIC_FORM_URL}/thailand/thaisimjp?thaisim_plan1_name=` : '/form?plan1_name=') + encodeURI(`エンジョイ5G ${howMuchData}プラン 月額${price}THB-1年契約`)}
        target='_blank'
        className='mt-5 border-2 bg-sim-Y-main text-white p-2 rounded-xl block text-center text-base md:text-3xl w-full md:w-[700px]'
      >
        オンラインでタイのSIMカードを契約
      </a>
    </ComponentLayout>
  )
}

export const ThaisimDiagnosisStart = () => {
  const [, setAnswers] = useSessionStorageAnswers()
  const [open, setOpen] = useSessionStorageOpenDialog()
  const start = () => {
    setAnswers([])
    setOpen(true)
  }

  return (
    <div className='px-4 w-full max-w-5xl mx-auto'>
      <div
        className={`relative rounded-xl border-4 text-base border-brand-purple-700 bg-brand-purple-50 text-brand-purple-700
        flex flex-col items-center bg-[url("/images/thailand-bg.svg")] bg-no-repeat bg-bottom`}
      >
        <div className='text-center py-8 md:py-16 space-y-6'>
          <div className='text-base md:text-lg whitespace-break-spaces'>
            あなたに最適なタイのSIMカードを<br className="block md:hidden"/>チャートでご案内いたします！
          </div>
          <div className='text-[40px] font-bold'>タイシム診断</div>
          <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger asChild>
              <Button
                className='rounded-full px-9 py-7 font-semibold text-lg bg-brand-purple-700 hover:bg-brand-purple-500 text-white'
                onClick={() => start()}
              >
                オススメサービス診断 START
              </Button>
            </DialogTrigger>
            <DialogContent
              className='bg-transparent border-0 p-4 max-w-3xl overflow-y-scroll max-h-screen no-scrollbar'
              autoFocus={false}
              showCloseButton={false}
            >
              <Flow />
            </DialogContent>
          </Dialog>
        </div>
        <div className='flex flex-row gap-8 md:absolute md:w-full md:bottom-0 md:justify-between md:px-1 pointer-events-none'>
          <img src='/images/woman-ico.svg' className='h-44 self-end' />
          <img src='/images/man-ico.svg' className='h-44 self-end' />
        </div>
      </div>
    </div>
  )
}
